import Vue from "vue"
import Vuex from "vuex"

import { globalState } from "@/lib/global-state"
import { DEFAULT_VIDEO_SIZE_LIMIT } from "@/lib/upload-helpers"
import { isPresent } from "@/lib/utils"

import workouts from "@/store/workouts"
import pwa from "@/store/pwa"
import auth from "@/store/auth"
import products from "@/store/products"
import programs from "@/store/programs"
import analytics from "@/store/analytics"
import access from "@/store/access"
import events from "@/store/events"
import myContent from "@/store/myContent"
import videos from "@/store/videos"
import packs from "@/store/packs"
import forms from "@/store/forms"
import pages from "@/store/pages"
import messages from "@/store/messages"
import tabbar from "@/store/tabbar"
import content from "@/store/content"
import overlays from "@/store/overlays"
import notifications from "@/store/notifications"
import schedule from "@/store/schedule"
import responsive from "@/store/responsive"
import pageActionPanel from "@/store/page-action-panel"
import subscriberProperties from "@/store/subscriber-properties"
import ui from "@/store/ui"
import media from "@/store/media"
import subscriber from "@/store/subscriber"
import localisation from "@/store/localisation"

Vue.use(Vuex)

const TAX_BEHAVIOR = {
  INCLUSIVE: "inclusive",
  EXCLUSIVE: "exclusive",
  INFERRED_BY_CURRENCY: "inferred_by_currency"
}

export default new Vuex.Store({
  state: {
    global: globalState()
  },

  modules: {
    workouts,
    pwa,
    auth,
    products,
    programs,
    analytics,
    access,
    events,
    myContent,
    videos,
    packs,
    forms,
    pages,
    messages,
    tabbar,
    content,
    overlays,
    notifications,
    schedule,
    pageActionPanel,
    responsive,
    subscriberProperties,
    ui,
    media,
    subscriber,
    localisation
  },

  mutations: {
    setGlobalState(state, globalState) {
      state.global = globalState
    }
  },

  getters: {
    account(state) {
      return state.global?.account
    },

    app(state) {
      return state.global?.app
    },

    appAccess(state) {
      return state.global?.access
    },

    appIcon(state) {
      return state.global?.app.icon
    },

    appSlug(state) {
      return state.global?.app.slug
    },

    appUrl(state) {
      return state.global?.app.url
    },

    appName(state) {
      return state.global?.app.name
    },

    trial(state) {
      return state.global?.trial
    },

    subscription(state) {
      return state.global?.subscription
    },

    isChatAvailable(_, __, ___, rootGetters) {
      return (
        (isPresent(rootGetters.subscription) &&
          !rootGetters.subscription.is_free &&
          rootGetters.subscription.active) ||
        !!rootGetters.trial?.active
      )
    },

    isMessagingEnabled(_, __, ___, rootGetters) {
      return (
        rootGetters.memberToAdminMessagingEnabled ||
        rootGetters.memberToMemberMessagingEnabled
      )
    },

    memberToAdminMessagingEnabled(state) {
      return !!state.global?.messaging?.member_to_admin_messaging_enabled
    },

    memberToMemberMessagingEnabled(state) {
      return !!state.global?.messaging?.member_to_member_messaging_enabled
    },

    canAcceptPayments(state) {
      return !!state.global?.payments.can_accept_payments
    },

    stripeAccountId(state) {
      return state.global?.payments.stripe_account_id
    },

    hasEnabledTaxCollection(state) {
      return state.global?.payments?.has_enabled_tax_collection
    },

    shouldShowTax(state) {
      return [
        TAX_BEHAVIOR.EXCLUSIVE,
        TAX_BEHAVIOR.INFERRED_BY_CURRENCY
      ].includes(state.global?.payments.tax_behavior)
    },

    showFitterBadge(state) {
      // Default to show the badge
      const isShown = state.global?.theme?.show_fitter_badge

      return typeof isShown === "boolean" ? isShown : true
    },

    stripeCountry(state) {
      return state.global?.payments.country
    },

    tabbarLinks(state) {
      return state.global?.tabbar.links
        ? state.global.tabbar.links
        : [
            {
              text: "Home",
              icon: "home",
              path: "/"
            },
            {
              text: "Schedule",
              icon: "calendar_today",
              path: "/me/schedule"
            },
            {
              text: "You",
              icon: "person",
              path: "/me/my-content"
            }
          ]
    },

    renderTimezone(state) {
      return (
        state.auth?.user?.timezone || state.global?.account?.timezone || null
      )
    },

    memberVideoUploadLimitMb(state) {
      return (
        state.global?.app?.video_upload_limit_mb || DEFAULT_VIDEO_SIZE_LIMIT
      )
    }
  }
})
