import Vue from "vue"

import store from "@/store"
import router from "@/router"

import EventBus, { EVENTS } from "@/lib/event-bus"

import "@/plugins/onsen-ui"
import "@/components/_global"

import RouterPlugin from "@/plugins/router"
import VueNotify from "@/plugins/notify"
import Localisation from "@/plugins/localisation"

Vue.use(RouterPlugin, { store, router })
Vue.use(VueNotify, {
  onDismiss: () => EventBus.$emit(EVENTS.toastDismissed),
  onShow: () => EventBus.$emit(EVENTS.toastShown)
})
Vue.use(Localisation)
